import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function About() {
  return (
    <Layout>
      <SEO title="About" />
      <div className="px-10 mb-20">
        <h1 className="mt-8 mb-4 font-semibold text-2xl">Project Information</h1>
        <h1 className="mt-8 mb-3 font-semibold text-xl">Credit</h1>
        <div className="mt-3 mb-4">
          <ul className="list-disc list-inside mt-2 mb-5">
            <li>Supervised by <a href="https://researchers.uq.edu.au/researcher/27868" target="_blank" rel="noreferrer" className="underline">Dr. Tong Chen</a> &amp; <a href="https://staff.itee.uq.edu.au/huang/" target="_blank" rel="noreferrer" className="underline">Prof. Zi (Helen) Huang</a>.</li>
            <li>Data Analysed by <a href="mailto:xurong.liang@uq.net.au" className="underline">Xurong Liang</a>.</li>
            <li>Visualised by <a href="https://www.nifu.me" target="_blank" rel="noreferrer" className="underline">Yiyun Zhang</a>.</li>
          </ul>
          <p className="mt-2 text-sm">&copy;&nbsp;2021 The University of Queensland, School of ITEE.</p>
        </div>
        <h1 className="mt-8 mb-4 font-semibold text-xl">Roadmap</h1>
        <ul className="list-disc list-inside mt-2 mb-5">
          <li>Research on D3.js (data-driven document)</li>
          <li>Integrate D3 with React and Next.js</li>
          <li>Create initial visualisation</li>
          <li>Implement data to the model</li>
          <li>Create interactive behaviours (zoom, drag, pan, click)</li>
          <li>Create node information sidebar</li>
          <li>Create timeline and graph status</li>
          <li>Integrate with real data</li>
          <li>Final website polish</li>
        </ul>
      </div>
    </Layout>
  );
}
